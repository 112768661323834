<ng-container *ngIf="pageReady$ | async; else showJenzLoading">
  <app-layout></app-layout>
</ng-container>

<ng-template #showJenzLoading>
  <div class="main-spinner-container">
    <p-progressSpinner
      strokeWidth="3"
      styleClass="whole-page-spinner"
      animationDuration="5s"
    ></p-progressSpinner>
    <img src="assets/images/jenz-logo.png" class="spinner-jenz-logo" />
  </div>
</ng-template>

<p-toast></p-toast>

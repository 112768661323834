import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Survey } from 'libs/types/survey';

@Component({
  selector: 'app-active-surveys-dialog',
  templateUrl: './active-surveys-dialog.component.html',
  styleUrls: ['./active-surveys-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActiveSurveysDialogComponent {
  @Input() visible: boolean;
  @Input() surveys: Survey[];
  @Input() loaded: boolean;

  @Output() close = new EventEmitter<void>();
  @Output() selected = new EventEmitter<string>();
  @Output() loadSurveys = new EventEmitter<void>();

  hideDialog() {
    this.close.emit();
  }

  surveySelected(id: string) {
    this.selected.emit(id);
  }

  dialogOpened() {
    this.loadSurveys.emit();
  }
}

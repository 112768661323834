import { SurveyQuestionType } from 'libs/enums/survey/survey-question-type.enum';

export interface ISurveyAttributes {
  title: string;
  intro_text: string;
  answer_time: number;
  ends_at: Date;
}

export interface ISurvey {
  attributes: ISurveyAttributes;
  id: string;
}

export interface ISurveyQuestion {
  id: string;
  attributes: ISurveyQuestionAttributes;
}

export interface ISurveyQuestionAttributes {
  allow_to_skip: boolean;
  answers: string[];
  breakable_answers: string[];
  is_breakable: boolean;
  possible_answers: string[];
  priority: number;
  question: string;
  type: SurveyQuestionType;
}

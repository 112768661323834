<div class="activate-account-container">
  <ng-container [ngSwitch]="activeStep">
    <ng-container *ngSwitchCase="activateAccountStepsEnum.Introduction"
      ><div>
        To create your Jenz account, please follow these few easy steps.
      </div>
      <button
        pButton
        label="Let's go!"
        (click)="navigateToStep(activateAccountStepsEnum.RequestCode)"
      ></button>
    </ng-container>

    <ng-container *ngSwitchCase="activateAccountStepsEnum.RequestCode">
      <app-request-code-form
        headerLabel="Welcome to Jenz"
        buttonLabel="Request activation code"
        (formSubmitted)="submitIntroductionForm($event)"
      ></app-request-code-form>
    </ng-container>

    <ng-container
      *ngSwitchCase="activateAccountStepsEnum.CodeReceivedConfirmation"
    >
      <div>
        We just sent activation code to your email. Please check your inbox or
        spam folder.
      </div>
      <span
        class="clickable-link clickable"
        (click)="
          navigateToStep(activateAccountStepsEnum.EnterCodeAndSetPassword)
        "
        >Enter activation code</span
      >
      <div>Didn't receive activation code on your email?</div>
      <span
        class="clickable-link clickable"
        (click)="navigateToStep(activateAccountStepsEnum.RequestCode)"
        >Resend code</span
      >
    </ng-container>

    <ng-container
      *ngSwitchCase="activateAccountStepsEnum.EnterCodeAndSetPassword"
    >
      <app-set-password-form
        headerlabel="Activation and password setup"
        [email]="email"
        (setPassword)="setUserPassword($event)"
      ></app-set-password-form>
    </ng-container>
  </ng-container>

  <p-button
    class="login-button"
    label="I already have an active account"
    [text]="true"
    (click)="navigateBack()"
  ></p-button>
</div>

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { NotificationsService } from 'libs/services/notifications/notifications.service';
import { MessageService } from 'primeng/api';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as actions from './notifications.actions';

@Injectable()
export class NotificationsEffects {
  loadAllNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadAllNotifications),
      switchMap(() =>
        // TODO - add pagination

        this.service.loadAllNotifications(true, 50, 0).pipe(
          map((response: any) => {
            return actions.loadAllNotificationsSuccess({
              notifications: response.data,
            });
          }),
          catchError((error) => {
            return of(actions.loadAllNotificationsError({ error }));
          })
        )
      )
    )
  );

  loadUnreadNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadUnreadNotificationsCount),
      switchMap(() =>
        this.service.loadUnreadNotificationsCount().pipe(
          map((count: number) => {
            return actions.loadUnreadNotificationsCountSuccess({
              count,
            });
          }),
          catchError((error) => {
            return of(actions.loadUnreadNotificationsCountError({ error }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private service: NotificationsService,
    private messageService: MessageService
  ) {}
}

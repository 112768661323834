import { createAction, props } from '@ngrx/store';
import { LoginData, SetPasswordData, User } from 'libs/types/auth';

export const login = createAction(
  '[Auth] Login',
  props<{ loginData: LoginData }>()
);

export const loginSuccess = createAction('[Auth] Login Success');

export const loginSetCurrentUser = createAction(
  '[Auth] Login Set Current User',
  props<{ user: User }>()
);

export const loginError = createAction('[Auth] Login Error');

export const logout = createAction('[Auth] Logout');

export const requestActivationCode = createAction(
  '[Auth] Request Activation Code',
  props<{ email: string }>()
);

export const requestActivationCodeSuccess = createAction(
  '[Auth] Request Activation Code Success'
);

export const requestActivationCodeError = createAction(
  '[Auth] Request Activation Code Error'
);

export const updateAuthStep = createAction(
  '[Auth] Update Auth Step',
  props<{ step: number }>()
);

export const updateActivateAccountStep = createAction(
  '[Auth] Update Activate Account Step',
  props<{ step: number }>()
);

export const setPassword = createAction(
  '[Auth] Set Password',
  props<{ data: SetPasswordData }>()
);

export const setPasswordSuccess = createAction(
  '[Auth] Set Password Success',
  props<{ loginData: LoginData }>()
);

export const setPasswordError = createAction('[Auth] Set Password Error');

export const updateForgotPasswordStep = createAction(
  '[Auth] Update Forgot Password Step',
  props<{ step: number }>()
);

export const getLoggedUsersData = createAction('[Auth] Get Logged Users Data');

export const updateCurrentUser = createAction(
  '[Auth] Update Current User',
  props<{ user: User }>()
);

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ForgotPasswordStepsEnum } from 'libs/enums/auth';
import { SetPasswordData } from 'libs/types/auth';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordComponent {
  email = '';
  forgotPasswordEnum = ForgotPasswordStepsEnum;

  @Input() activeStep = ForgotPasswordStepsEnum.RequestCode;

  @Output() navigateToLogin = new EventEmitter<void>();
  @Output() updateStep = new EventEmitter<number>();
  @Output() setPassword = new EventEmitter<SetPasswordData>();
  @Output() requestActivationCode = new EventEmitter<string>();

  requestPasswordReset(email: string) {
    this.email = email;

    this.requestActivationCode.emit(email);
  }

  navigateBack() {
    this.navigateToLogin.emit();
  }

  navigateToStep(step: number) {
    this.updateStep.emit(step);
  }

  setUserPassword(data: SetPasswordData) {
    this.setPassword.emit(data);
  }
}

<form [formGroup]="form">
  <div class="request-code-form">
    <h3 class="full-width">{{ headerLabel }}</h3>
    <span class="p-float-label full-width">
      <input pInputText formControlName="email" class="full-width" />
      <label>Your corporate email</label>
    </span>
    <button
      pButton
      class="full-width"
      [label]="buttonLabel"
      (click)="submit()"
    ></button>
  </div>
</form>

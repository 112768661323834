import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LoginData } from 'libs/types/auth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
  showPassword = false;
  showLoginScreen = true;

  @Output() login = new EventEmitter<LoginData>();
  @Output() forgotPasswordClicked = new EventEmitter<void>();
  @Output() activateAccountClicked = new EventEmitter<void>();

  loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]],
  });

  constructor(private fb: FormBuilder) {}

  get passwordType() {
    return this.showPassword ? 'text' : 'password';
  }

  submit() {
    const loginData = this.loginForm.value as LoginData;

    this.login.emit(loginData);
  }

  viewPassword() {
    this.showPassword = !this.showPassword;
  }

  forgotPassword() {
    this.forgotPasswordClicked.emit();
  }

  activateAccount() {
    this.activateAccountClicked.emit();
  }
}

import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'libs/services/auth';
import { AuthState } from 'libs/states/auth';
import * as uiActions from 'libs/states/ui-state/+state/ui.actions';
import { UiState } from 'libs/states/ui-state/+state/ui.reducer';
import { PrimeNGConfig } from 'primeng/api';
import { BehaviorSubject, distinctUntilChanged, tap } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Jenz-Web';
  token = localStorage.getItem('token');

  isMobileSubject = new BehaviorSubject<boolean>(false);
  isMobile$ = this.isMobileSubject.asObservable().pipe(
    distinctUntilChanged(),
    tap((isMobile) =>
      this.uiState.dispatch(uiActions.updateIsMobile({ isMobile }))
    )
  );

  pageReady = new BehaviorSubject<boolean>(false);
  pageReady$ = this.pageReady.asObservable();

  constructor(
    private authService: AuthService,
    private authState: Store<AuthState>,
    private primeNgConfig: PrimeNGConfig,
    private translate: TranslateService,
    private uiState: Store<UiState>,
    private router: Router
  ) {
    this.configureTranslateService();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.detectIsMobile();
  }

  // Used to track if users has clicked on the mention
  @HostListener('click', ['$event'])
  onClick(e) {
    if (e.target.localName !== 'mark' && e.target.nodeName !== 'MARK') return;

    const className = e.target?.className as string;

    if (!className?.includes('clickable')) return;

    const userIdStart = className.indexOf('=') + 1;

    const userId = className.slice(userIdStart, className?.length);

    if (isNaN(Number(userId))) return;

    this.router.navigate(['people', { userId }]);
  }

  ngOnInit() {
    if (this.token) {
      setTimeout(() => {
        this.pageReady.next(true);
      }, 2000);

      this.authService.getGlobalApplicationConfiguration().subscribe();
    } else this.pageReady.next(true);

    this.detectIsMobile();
    this.isMobile$.subscribe();
  }

  configureTranslateService() {
    this.translate.addLangs(['en', 'hr']);
    this.translate.setDefaultLang('en');
    const selectedLanguage = localStorage.getItem('selected-language') ?? 'en';
    this.translate.use(selectedLanguage);

    this.translate
      .get('primeNg')
      .subscribe((res) => this.primeNgConfig.setTranslation(res));
  }

  detectIsMobile(): void {
    this.isMobileSubject.next(window.innerWidth < 770);
  }
}

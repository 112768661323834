<p-dialog
  header="Notifications"
  [(visible)]="visible"
  (onShow)="loadNotifications()"
  (onHide)="hideDialog()"
  [modal]="true"
  [draggable]="false"
  styleClass="notifications-dialog"
>
  <ng-container *ngIf="!loaded; else showNotifications">
    <div class="spinner-container">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </ng-container>
  <ng-template #showNotifications>
    <div class="notification-list">
      <ng-container *ngFor="let notification of notifications">
        <div
          class="notification-container clickable"
          (click)="openNotification(notification)"
        >
          <img [src]="notification.type | notificationIcon" />
          {{ notification?.message }}
        </div>
      </ng-container>
    </div>
  </ng-template>
</p-dialog>

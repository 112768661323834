import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { NotificationTypeEnum } from 'libs/enums/notification';
import { Notification } from 'libs/types/notifications/notification.type';

@Component({
  selector: 'app-notifications-dialog',
  templateUrl: './notifications-dialog.component.html',
  styleUrls: ['./notifications-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsDialogComponent {
  @Input() visible: boolean;
  @Input() notifications: any[];
  @Input() loaded: boolean;

  @Output() loadAllNotifications = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();

  constructor(private router: Router) {}

  hideDialog() {
    this.close.emit();
  }

  loadNotifications() {
    this.loadAllNotifications.emit();
  }

  openNotification(notification: Notification) {
    switch (notification.type) {
      case NotificationTypeEnum.userComment:
      case NotificationTypeEnum.userCommentComment:
      case NotificationTypeEnum.userLike:
      case NotificationTypeEnum.storyMention:
        this.router.navigate([
          'stories',
          { storyId: notification?.storyData?.story_id },
        ]);
        break;
      case NotificationTypeEnum.userKudos:
        console.log('TODO - Not implemented yet');
        break;
      default:
        console.log('TODO - Not implemented yet');
        break;
    }

    this.close.emit();
  }
}

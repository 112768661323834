import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiResponseSuccess,
  LoginData,
  LoginResponse,
  SetPasswordData,
  User,
} from 'libs/types/auth';
import { ITenantConfiguration } from 'libs/types/config/interfaces/tenant-configuration.interface';
import { TenantConfiguration } from 'libs/types/config/tenant-configuration.type';
import { BehaviorSubject, map, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { endpoints } from './auth.endpoints';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  baseUrl = environment.baseApiRoute;

  configurationSubject = new BehaviorSubject<TenantConfiguration>(null);
  configuration$ = this.configurationSubject.asObservable();

  constructor(private http: HttpClient) {}

  login(data: LoginData) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-type': 'application/json',
    });
    const url = `${this.baseUrl}${endpoints.login}`;
    return this.http.post<LoginResponse>(url, data, { headers });
  }

  getLoggedUserData() {
    const url = `${this.baseUrl}${endpoints.loggedUserData}`;

    return this.http
      .get<any>(url) // UPDATE TYPE
      .pipe(map((response) => new User(response?.data)));
  }

  requestActivationCode(email: string) {
    const url = `${this.baseUrl}${endpoints.requestActivationCode}`;

    return this.http.post<any>(url, { email }); // UPDATE TYPE;
  }

  setPassword(data: SetPasswordData) {
    const url = `${this.baseUrl}${endpoints.setPassword}`.replace(
      '{token}',
      data.token
    );

    return this.http.post<any>(url, data); // UPDATE TYPE;
  }

  getGlobalApplicationConfiguration() {
    const url = `${this.baseUrl}${endpoints.globalApplicationConfiguration}`;

    return this.http.get<ApiResponseSuccess<ITenantConfiguration>>(url).pipe(
      map((response) => new TenantConfiguration(response.data)),
      tap((config) => this.configurationSubject.next(config))
    );
  }
}

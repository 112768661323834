import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SetPasswordData } from 'libs/types/auth';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-set-password-form',
  templateUrl: './set-password-form.component.html',
  styleUrls: ['./set-password-form.component.scss'],
})
export class SetPasswordFormComponent {
  showPassword = false;
  showConfirmPassword = false;

  @Input() email = '';
  @Input() headerlabel = '';

  @Output() setPassword = new EventEmitter<SetPasswordData>();

  setPasswordForm = this.fb.group({
    activationCode: ['', [Validators.required, Validators.minLength(5)]],
    password: ['', [Validators.required]],
    confirmPassword: ['', [Validators.required]],
  });

  get formValue() {
    return this.setPasswordForm.value;
  }

  get passwordType() {
    return this.showPassword ? 'text' : 'password';
  }

  get confirmPasswordType() {
    return this.showConfirmPassword ? 'text' : 'password';
  }

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService
  ) {}

  submit() {
    if (this.formValue.password !== this.formValue.confirmPassword) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Make sure your passwords match!',
      });
      return;
    }

    const data = {
      email: this.email,
      token: this.formValue.activationCode,
      password: this.formValue.password,
    };

    this.setPassword.emit(data);
  }

  viewPassword() {
    this.showPassword = !this.showPassword;
  }

  viewConfirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }
}

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { NotificationsEffects } from './+state/notifications.effects';
import {
  initialState,
  notificaionsReducer,
  notificationsFeatureKey,
} from './+state/notifications.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(notificationsFeatureKey, notificaionsReducer, {
      initialState,
    }),
    EffectsModule.forFeature([NotificationsEffects]),
  ],
  providers: [HttpClientModule, MessageService],
})
export class NotificationsStateModule {}

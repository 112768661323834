import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { SurveyEffects } from './+state/survey.effects';
import {
  initialState,
  surveyFeatureKey,
  surveyReducer,
} from './+state/survey.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(surveyFeatureKey, surveyReducer, {
      initialState,
    }),
    EffectsModule.forFeature([SurveyEffects]),
  ],
  providers: [HttpClientModule, MessageService],
})
export class SurveyStateModule {}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponseSuccess } from 'libs/types/auth';
import { ISurvey, Survey } from 'libs/types/survey';
import { SurveyQuestion } from 'libs/types/survey/survey-question.type';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { endpoints } from './survey.endpoints';

@Injectable({
  providedIn: 'root',
})
export class SurveyService {
  baseApiRoute = environment.baseApiRoute;

  constructor(private http: HttpClient) {}

  loadActiveSurveys() {
    const url = `${this.baseApiRoute}${endpoints.getActiveSurveys}`;

    return this.http
      .get<ApiResponseSuccess<ISurvey[]>>(url)
      .pipe(
        map((response) => response.data.map((survey) => new Survey(survey)))
      );
  }

  loadActiveSurveysCount() {
    const url = `${this.baseApiRoute}${endpoints.getActiveSurveys}`;

    let params = new HttpParams();

    params = params.append('page[offset]', 0);
    params = params.append('page[limit]', 0);

    return this.http
      .get<ApiResponseSuccess<ISurvey[]>>(url, { params })
      .pipe(map((response) => response.meta.page.total));
  }

  loadSurveyById(id: string) {
    const url = `${this.baseApiRoute}${endpoints.getSurveyById.replace(
      '{id}',
      id
    )}`;

    return this.http.get<ApiResponseSuccess<ISurvey>>(url).pipe(
      map(
        (response) =>
          new Survey(
            response.data,
            response.included
              .filter((x) => x.type === 'question')
              .map(
                (question) =>
                  new SurveyQuestion({
                    id: question.id,
                    ...question,
                  })
              )
          )
      )
    );
  }

  submitSurvey(id, data) {
    const url = `${this.baseApiRoute}${endpoints.submitSurvey.replace(
      '{id}',
      id
    )}`;

    return this.http.post(url, { data });
  }
}

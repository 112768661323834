import { Action, createReducer, on } from '@ngrx/store';
import * as actions from './ui.actions';

export const uiFeatureKey = 'ui';

export interface UiState {
  isMobile: boolean;
}

export const initialState: UiState = {
  isMobile: false,
};

export const uiReducer = createReducer(
  initialState,
  on(actions.updateIsMobile, (state, action) => {
    return {
      ...state,
      isMobile: action.isMobile,
    };
  })
);

export function reducer(state: UiState, action: Action) {
  return reducer(state, action);
}

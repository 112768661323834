import { Pipe, PipeTransform } from '@angular/core';
import { NotificationTypeEnum } from 'libs/enums/notification';

@Pipe({
  name: 'notificationIcon',
  pure: true,
})
export class NotificationIconPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    if (!value?.length) return '';

    switch (value) {
      case NotificationTypeEnum.userKudos:
        return 'assets/icons/kudos-icon.png';
      case NotificationTypeEnum.userLike:
        return 'assets/icons/story-like-colored.svg';
      case NotificationTypeEnum.storyMention:
      case NotificationTypeEnum.userComment:
      case NotificationTypeEnum.userCommentComment:
        return 'assets/icons/comment.svg';
      default:
        return '';
    }
  }
}

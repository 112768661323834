import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { AuthEffects } from './+state/auth.effects';
import {
  authFeatureKey,
  authReducer,
  initialState,
} from './+state/auth.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(authFeatureKey, authReducer, {
      initialState,
    }),
    EffectsModule.forFeature([AuthEffects]),
  ],
  providers: [HttpClientModule, MessageService],
})
export class AuthStateModule {}

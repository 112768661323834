import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'libs/auth/guards/auth.guard';
import { LoginPageGuard } from 'libs/auth/guards/login-page.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('libs/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [LoginPageGuard],
  },
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'stories', pathMatch: 'full' },
      {
        path: 'stories',
        loadChildren: () =>
          import('libs/stories/stories.module').then((m) => m.StoriesModule),
      },
      {
        path: 'story',
        loadChildren: () =>
          import('libs/story-form/story-form.module').then(
            (m) => m.StoryFormModule
          ),
      },
      {
        path: 'people',
        loadChildren: () =>
          import('libs/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'shoutbox',
        loadChildren: () =>
          import('libs/shoutbox/shoutbox.module').then((m) => m.ShoutboxModule),
      },
      {
        path: 'survey',
        loadChildren: () =>
          import('libs/survey/survey.module').then((m) => m.SurveyModule),
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('libs/notifications/notifications.module').then(
            (m) => m.NotificationsModule
          ),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('libs/my-profile/my-profile.module').then(
            (m) => m.MyProfileModule
          ),
      },
      { path: ':misstypedPath', redirectTo: 'stories' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

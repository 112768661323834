import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { initialState, uiFeatureKey, uiReducer } from './+state/ui.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(uiFeatureKey, uiReducer, {
      initialState,
    }),
  ],
})
export class UiStateModule {}

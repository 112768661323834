import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UsersService } from 'libs/services/users';
import { Subject, debounceTime, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-highlight-textarea',
  templateUrl: './highlight-textarea.component.html',
  styleUrls: ['./highlight-textarea.component.scss'],
})
export class HighlightTextareaComponent implements OnChanges {
  highlightedUsers = [];

  @ViewChild('backdrop') $backdrop: ElementRef<HTMLDivElement>;
  @ViewChild('textarea') $textarea: ElementRef<HTMLTextAreaElement>;

  @Input() label = '';
  @Input() textValue = '';
  @Input() showMentionsAbove = true;
  @Input() textAreaKey = '';

  @Output() textChanged = new EventEmitter<string>();

  get highlightedText() {
    return this.applyHighlights(this.textValue);
  }

  constructor(private usersService: UsersService) {}

  applyHighlights(text) {
    text = text ? text.replace(/\n$/g, '\n\n') : '';
    this.highlightedUsers.forEach((x) => {
      text = text.replace(new RegExp(x, 'g'), '<mark>$&</mark>');
    });
    return text;
  }

  handleScroll() {
    var scrollTop = this.$textarea.nativeElement.scrollTop;
    this.$backdrop.nativeElement.scrollTop = scrollTop;

    var scrollLeft = this.$textarea.nativeElement.scrollLeft;
    this.$backdrop.nativeElement.scrollLeft = scrollLeft;
  }

  valueUpdated(textAreaValue) {
    let value = textAreaValue;

    if (this.selectedMentionedUsers.length) {
      this.selectedMentionedUsers.map((mentionedUser) => {
        value = value.replaceAll(
          `@${mentionedUser.label}`,
          `[@${mentionedUser.label}:${mentionedUser.id}]`
        );
      });
    }

    if (this.loadedMentionedUsers.length) {
      this.loadedMentionedUsers.map((mentionedUser) => {
        value = value.replaceAll(
          mentionedUser.mentionUsername,
          mentionedUser.mention
        );
      });
    }

    this.textChanged.emit(value);
  }

  // Mentions

  loadedMentionedUsers = [];
  mentionOptions = [];
  selectedMentionedUsers = [];

  get mentionItems() {
    return this.mentionOptions.map((x) => {
      return {
        label: x.name.replaceAll(' ', ''),
        name: x.name,
        id: x.id,
        profileImage: x.profileImage,
        inactive: !x.lastActivity?.date,
      };
    });
  }

  loadMentionOptions = new Subject<string>();
  loadMentionOptions$ = this.loadMentionOptions.asObservable().pipe(
    debounceTime(300),
    switchMap((searchValue) => this.usersService.loadUsers(5, 0, searchValue)),
    tap((data) => (this.mentionOptions = data.data))
  );

  mentionSearchChanged(text): void {
    this.loadMentionOptions.next(text);
  }

  mentionItemSelected(user): void {
    const mentionedUsers = this.selectedMentionedUsers.filter(
      (x) => x.id !== user.id
    );

    this.selectedMentionedUsers = [...mentionedUsers, user];

    this.highlightedUsers = [...this.highlightedUsers, `@${user.label}`];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['textValue']) {
      const textValue = changes['textValue'].currentValue;

      const regex = /\[@[a-zA-Zšđčćž]+:\d+\]/g;

      const matches = textValue.matchAll(regex);

      if (matches) {
        for (const match of matches) {
          const mention = match[0] as string;

          const usernameEnd = mention.indexOf(':');

          const mentionUsername = mention.slice(1, usernameEnd);

          this.highlightedUsers = [...this.highlightedUsers, mentionUsername];

          this.textValue = this.textValue.replaceAll(match[0], mentionUsername);

          const loadedMentionedUser = { mention, mentionUsername };

          this.loadedMentionedUsers = [
            ...this.loadedMentionedUsers.filter((x) => x.mention !== mention),
            loadedMentionedUser,
          ];
        }
      }
    }
  }

  resetValue() {
    this.textValue = '';
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SurveyState, surveyFeatureKey } from './survey.reducer';

export const selectSurveyState =
  createFeatureSelector<SurveyState>(surveyFeatureKey);

export const getSurveyDialogVisible = createSelector(
  selectSurveyState,
  (state) => state.surveyDialogVisible
);

export const getSurveys = createSelector(
  selectSurveyState,
  (state) => state.activeSurveys
);

export const getNumberOfActiveSurveys = createSelector(
  selectSurveyState,
  (state) => state.activeSurveysCount
);

export const getCurrentSurvey = createSelector(
  selectSurveyState,
  (state) => state?.currentSurvey
);

export const getSurveySidebarActive = createSelector(
  selectSurveyState,
  (state) => !!state?.currentSurvey
);

export const getSurveysLoaded = createSelector(
  selectSurveyState,
  (state) => state?.surveysLoaded
);

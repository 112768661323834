import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthState } from 'libs/states/auth';
import * as authActions from 'libs/states/auth/+state/auth.actions';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private router: Router, private store: Store<AuthState>) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = localStorage.getItem('currentUser');

    if (!currentUser) {
      this.router.navigate(['login']);
      return false;
    }

    if (state.url === '/login') this.router.navigate(['']);

    this.store.dispatch(authActions.getLoggedUsersData());

    return true;
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ActivateAccountStepsEnum } from 'libs/enums/auth';
import { SetPasswordData } from 'libs/types/auth';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivateAccountComponent {
  email = '';
  activateAccountStepsEnum = ActivateAccountStepsEnum;

  @Input() activeStep = ActivateAccountStepsEnum.Introduction;

  @Output() requestActivationCode = new EventEmitter<string>();
  @Output() updateStep = new EventEmitter<number>();
  @Output() navigateToLogin = new EventEmitter<void>();
  @Output() navigateToSetPassword = new EventEmitter<void>();
  @Output() setPassword = new EventEmitter<SetPasswordData>();

  navigateToStep(step: number) {
    this.updateStep.emit(step);
  }

  submitIntroductionForm(email: string) {
    this.email = email;

    this.requestActivationCode.emit(email);
  }

  navigateBack() {
    this.navigateToLogin.emit();
  }

  setUserPassword(data: SetPasswordData) {
    this.setPassword.emit(data);
  }
}

<form [formGroup]="setPasswordForm">
  <div class="set-password-form">
    <h3 class="full-width">{{ headerlabel }}</h3>
    <span class="p-float-label full-width">
      <input pInputText formControlName="activationCode" class="full-width" />
      <label>Your activation code</label>
    </span>
    <span class="p-input-icon-right p-float-label">
      <input [type]="passwordType" pInputText formControlName="password" />
      <i class="pi pi-eye-slash clickable" (click)="viewPassword()"></i>
      <label>Password</label>
    </span>
    <span class="p-input-icon-right p-float-label">
      <input
        [type]="confirmPasswordType"
        pInputText
        formControlName="confirmPassword"
      />
      <i class="pi pi-eye-slash clickable" (click)="viewConfirmPassword()"></i>
      <label>Password</label>
    </span>
    <button
      pButton
      type="password"
      class="full-width"
      label="Start Jenzing!"
      (click)="submit()"
    ></button>
  </div>
</form>

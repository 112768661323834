<div class="forgot-password-container">
  <ng-container [ngSwitch]="activeStep">
    <ng-container *ngSwitchCase="forgotPasswordEnum.RequestCode"
      ><app-request-code-form
        headerLabel="Request password reset"
        buttonLabel="Send request"
        (formSubmitted)="requestPasswordReset($event)"
      ></app-request-code-form>
    </ng-container>

    <ng-container *ngSwitchCase="forgotPasswordEnum.CodeReceivedConfirmation">
      <div>
        To be able to reset your password you will need to enter activation
        code. We just sent you one to your email. Please check your inbox or
        spam folder.
      </div>
      <span
        class="clickable-link clickable"
        (click)="navigateToStep(forgotPasswordEnum.EnterCodeAndSetPassword)"
        >Enter activation code</span
      >
      <div>Didn't receive activation code on your email?</div>
      <span
        class="clickable-link clickable"
        (click)="navigateToStep(forgotPasswordEnum.RequestCode)"
        >Resend code</span
      >
    </ng-container>

    <ng-container *ngSwitchCase="forgotPasswordEnum.EnterCodeAndSetPassword">
      <app-set-password-form
        headerlabel="Reset password"
        [email]="email"
        (setPassword)="setUserPassword($event)"
      ></app-set-password-form>
    </ng-container>
  </ng-container>

  <p-button
    class="login-button"
    label="Back to login"
    [text]="true"
    (click)="navigateBack()"
  ></p-button>
</div>

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponseSuccess, IUser, User } from 'libs/types/auth';
import { Department } from 'libs/types/department/department.type';
import { IDepartment } from 'libs/types/department/interfaces/department.interface';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { endpoints } from './users.endpoints';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  baseApiRoute = environment.baseApiRoute;

  constructor(private http: HttpClient) {}

  loadUsers(
    limit: number,
    offset: number,
    searchValue: string,
    filters?: {
      away?: boolean;
      newUsers?: boolean;
      departmentId?: string;
    }
  ) {
    let params = new HttpParams();
    let pageLimit = limit;

    if (pageLimit > 0) params = params.append('page[limit]', limit);

    if (offset > 0) params = params.append('page[offset]', offset);

    params = params.append('sort', 'lastName');

    if (searchValue?.length) {
      params = params.append('filter[query]', searchValue);
    }

    if (filters?.away) {
      params = params.append('filter[away_status][]', 'sick');
      params = params.append('filter[away_status][]', 'biz');
      params = params.append('filter[away_status][]', 'remote');
      params = params.append('filter[away_status][]', 'holiday');
    }

    if (filters?.newUsers) {
      params = params.append('filter[new]', 'true');
    }

    if (filters?.departmentId) {
      params = params.append('filter[department]', filters.departmentId);
    }

    const url = `${this.baseApiRoute}${endpoints.loadUsers}`;

    return this.http
      .get<ApiResponseSuccess<IUser[]>>(`${url}`, { params })
      .pipe(
        map((response) => {
          return {
            data: response.data.map((userData: IUser) => new User(userData)),
            meta: response.meta,
          };
        })
      );
  }

  loadDepartments(limit: number, offset: number, searchValue: string) {
    const url = `${this.baseApiRoute}${endpoints.loadDepartments}`;

    let params = new HttpParams();

    params = params.append('page[limit]', limit);
    params = params.append('page[offset]', offset);

    params = params.append('sort', 'name');

    if (searchValue?.length) {
      params = params.append('filter[query]', searchValue);
    }

    return this.http
      .get<ApiResponseSuccess<IDepartment[]>>(`${url}`, { params })
      .pipe(
        map((response) => {
          return {
            data: response.data.map(
              (userData: IDepartment) => new Department(userData)
            ),
            meta: response.meta,
          };
        })
      );
  }

  updateMyStatus(type: string, fromDate: string, toDate: string) {
    const url = `${this.baseApiRoute}${endpoints.updateAwayStatus}`;

    const attributes = {
      type,
      date_from: fromDate,
      date_to: toDate,
    };

    const data = {
      type: 'away',
      attributes,
    };

    return this.http.post(url, { data });
  }

  updateMyProfile(userId, attributes) {
    const url = `${this.baseApiRoute}${endpoints.updateMyProfile}`.replace(
      '{userId}',
      userId
    );

    const data = {
      id: userId,
      type: 'user',
      attributes,
    };

    return this.http
      .patch<ApiResponseSuccess<IUser>>(url, { data })
      .pipe(map((response) => new User(response.data)));
  }

  loadUserById(userId) {
    const url = `${this.baseApiRoute}${endpoints.loadUsers}/${userId}`;

    return this.http
      .get<ApiResponseSuccess<IUser>>(url)
      .pipe(map((response) => new User(response.data)));
  }

  uploadImage(formData: FormData) {
    const url = `${this.baseApiRoute}${endpoints.uploadAttachment}`;

    return this.http
      .post(url, formData)
      .pipe(map((response: any) => response?.data?.id));
  }
}

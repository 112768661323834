<form [formGroup]="loginForm">
  <div class="login-form">
    <span class="p-input-icon-right p-float-label">
      <input type="text" pInputText formControlName="email" />
      <label>Email</label>
    </span>
    <div class="password">
      <span class="p-input-icon-right p-float-label">
        <input [type]="passwordType" pInputText formControlName="password" />
        <i class="pi pi-eye-slash clickable" (click)="viewPassword()"></i>
        <label>Password</label>
      </span>
      <span class="forgot-password clickable" (click)="forgotPassword()"
        >Forgot password?</span
      >
    </div>

    <button
      pButton
      label="Login"
      [ngClass]="{ disabled: !loginForm.valid }"
      [disabled]="!loginForm.valid"
      (click)="submit()"
    ></button>

    <p-button
      class="activate-account-button"
      label="Activate account"
      [text]="true"
      (click)="activateAccount()"
    ></p-button>
  </div>
</form>

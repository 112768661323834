<ng-container
  *ngIf="{
    checkForUpdates: checkForUpdates$ | async,
    notificationsVisible: notificationsVisible$ | async
  } as data"
>
  <div class="layout">
    <ng-container *ngIf="isAuth$ | async">
      <div class="sidebar">
        <app-sidebar
          [userProfileImage]="userProfileImage$ | async"
          [numberOfUnreadNotifications]="unreadNotificationsCount$ | async"
          [numberOfActiveSurveys]="numberOfActiveSurveys$ | async"
          [notificationsActive]="data.notificationsVisible"
          [surveyActive]="surveySidebarActive$ | async"
          [isMobile]="isMobile$ | async"
          (logout)="logout()"
          (openNotifications)="toggleNotificationsDialog(true)"
          (showSurveyDialog)="toggleSurveyDialog(true)"
        ></app-sidebar>
      </div>
    </ng-container>
    <div class="content-container" id="content-container">
      <router-outlet></router-outlet>
    </div>
  </div>

  <ng-container *ngIf="data.notificationsVisible">
    <app-notifications-dialog
      [visible]="data.notificationsVisible"
      [notifications]="notifications$ | async"
      [loaded]="notificationsLoaded$ | async"
      (close)="toggleNotificationsDialog(false)"
      (loadAllNotifications)="loadAllNotifications()"
    ></app-notifications-dialog>
  </ng-container>

  <ng-container *ngIf="surveyDialogVisible$ | async as visible">
    <app-active-surveys-dialog
      [visible]="visible"
      [surveys]="surveys$ | async"
      [loaded]="surveysLoaded$ | async"
      (selected)="surveySelected($event)"
      (close)="toggleSurveyDialog(false)"
      (loadSurveys)="loadSurveys()"
    ></app-active-surveys-dialog>
  </ng-container>
</ng-container>

import { SurveyQuestionType } from 'libs/enums/survey/survey-question-type.enum';
import { ISurveyQuestion } from './interfaces/survey.interface';

export class SurveyQuestion {
  id: string;
  question: string;
  answers: any;
  type: SurveyQuestionType;
  possibleAnswers: string[];
  allowSkip: boolean;
  priority: number;
  breakable: boolean;
  breakableAnswers: string[];

  constructor(data: ISurveyQuestion) {
    this.id = data.id;
    this.question = data.attributes.question;
    this.answers = data.attributes.answers;
    this.type = data.attributes.type;
    this.possibleAnswers = data.attributes.possible_answers;
    this.allowSkip = data.attributes.allow_to_skip;
    this.priority = data.attributes.priority;
    this.breakable = data.attributes.is_breakable;
    this.breakableAnswers = data.attributes.breakable_answers;
  }
}

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AuthStateModule } from 'libs/states/auth';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { ActivateAccountComponent } from './ui/components/activate-account/activate-account.component';
import { ForgotPasswordComponent } from './ui/components/forgot-password/forgot-password.component';
import { LoginComponent } from './ui/components/login/login.component';
import { RequestCodeFormComponent } from './ui/components/request-code-form/request-code-form.component';
import { SetPasswordFormComponent } from './ui/components/set-password-form/set-password-form.component';
import { AuthContainerComponent } from './ui/containers/auth-container/auth-container.component';

const routes: Routes = [
  {
    path: '',
    component: AuthContainerComponent,
  },
];
@NgModule({
  declarations: [
    LoginComponent,
    AuthContainerComponent,
    ActivateAccountComponent,
    ForgotPasswordComponent,
    SetPasswordFormComponent,
    RequestCodeFormComponent,
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    InputTextModule,
    ButtonModule,
    ToastModule,
    FormsModule,
    ReactiveFormsModule,
    AuthStateModule,
    RouterModule.forChild(routes),
  ],
  providers: [HttpClientModule, MessageService],
})
export class AuthModule {}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss'],
})
export class UploadFilesComponent {
  @Input() imageUrls: any[];

  @Output() filesDropped = new EventEmitter<File[]>();
  @Output() orderUpdated = new EventEmitter<{
    imageOrder: number;
    direction: number;
  }>();
  @Output() removeFile = new EventEmitter<number>();
  @Output() filesToUploadChanged = new EventEmitter<any[]>();

  responsiveOptions = [
    {
      breakpoint: '1400px',
      numVisible: 6,
      numScroll: 1,
    },
    {
      breakpoint: '1250px',
      numVisible: 5,
      numScroll: 1,
    },
    {
      breakpoint: '1150px',
      numVisible: 4,
      numScroll: 1,
    },
    {
      breakpoint: '1000px',
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: '850px',
      numVisible: 2,
      numScroll: 1,
    },
  ];

  removeImage(order: number) {
    this.removeFile.next(order);
  }

  updateFileOrder(imageOrder: number, direction: number) {
    const data = { imageOrder, direction };

    this.orderUpdated.next(data);
  }

  dropHandler(files: File[], inputMethod: string) {
    const fileArray = Object.values(files).map((file) => file);

    this.filesDropped.emit(fileArray);
  }
}

export enum NotificationTypeEnum {
  userKudos = 'user_kudos',
  storyMention = 'story_mention',
  userCommentComment = 'user_comment_comment',
  userCommentLike = 'user_comment_like',
  userComment = 'user_comment',
  userLike = 'user_like',
  userCommentMention = 'user_comment_mention',
  commentMention = 'comment_mention',
}

<p-dialog
  [header]="'sidebar.surveys' | translate"
  [(visible)]="visible"
  (onHide)="hideDialog()"
  (onShow)="dialogOpened()"
  [modal]="true"
  [draggable]="false"
  styleClass="surveys-dialog"
>
  <ng-template pTemplate="body">
    <div class="surveys-list">
      <ng-container *ngFor="let survey of surveys">
        <div
          class="survey-list-item clickable"
          (click)="surveySelected(survey.id)"
        >
          <div class="survey-title">{{ survey.title }}</div>
          <div class="survey-info">
            <i class="pi pi-clock"></i>
            <!-- Takes {{ survey.answerTime }} minutes -->
            {{
              "surveys.surveyMinutes"
                | translate : { minutes: survey.answerTime }
            }}
          </div>
          <div class="survey-info">
            <i class="pi pi-hourglass"></i>
            {{ "surveys.surveyEndsAt" | translate }}
            {{ survey.endsAt | localizedDate : "d MMMM y hh:mm" }}
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>
</p-dialog>

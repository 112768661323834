import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-request-code-form',
  templateUrl: './request-code-form.component.html',
  styleUrls: ['./request-code-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestCodeFormComponent {
  @Input() buttonLabel = '';
  @Input() headerLabel = '';

  @Output() formSubmitted = new EventEmitter<string>();

  form = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
  });

  get email() {
    return this.form.value.email;
  }

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService
  ) {}

  submit() {
    if (this.form.invalid) {
      this.messageService.add({
        severity: 'error',
        summary: 'Login failed',
        detail: 'Please enter valid email',
      });
      return;
    }

    this.formSubmitted.emit(this.email);
  }
}

import { Action, createReducer, on } from '@ngrx/store';
import { Survey } from 'libs/types/survey';
import * as actions from './survey.actions';

export const surveyFeatureKey = 'survey';

export interface SurveyState {
  activeSurveys: Survey[];
  activeSurveysCount: number;
  surveyDialogVisible: boolean;
  currentSurvey?: Survey;
  surveysLoaded: boolean;
}

export const initialState: SurveyState = {
  activeSurveys: [],
  activeSurveysCount: 0,
  surveyDialogVisible: false,
  surveysLoaded: false,
};

export const surveyReducer = createReducer(
  initialState,
  on(actions.loadActiveSurveysSuccess, (state, action) => {
    return {
      ...state,
      activeSurveys: action.surveys,
      surveysLoaded: true,
    };
  }),
  on(actions.loadActiveSurveysCountSuccess, (state, action) => {
    return {
      ...state,
      activeSurveysCount: action.count,
    };
  }),
  on(actions.toggleSurveyDialog, (state, action) => {
    return {
      ...state,
      surveyDialogVisible: action.visible,
      surveysLoaded: false,
    };
  }),
  on(actions.loadSurveySuccess, (state, action) => {
    return {
      ...state,
      currentSurvey: action.survey,
    };
  }),
  on(actions.clearCurrentSurvey, (state) => {
    const { currentSurvey, ...restOfState } = state;

    return {
      ...restOfState,
    };
  })
);

export function reducer(state: SurveyState, action: Action) {
  return reducer(state, action);
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {
  searchText = '';

  @Input() userProfileImage: string;
  @Input() numberOfUnreadNotifications = 0;
  @Input() numberOfActiveSurveys = 0;

  @Input() notificationsActive = false;
  @Input() surveyActive = false;
  @Input() isMobile = false;

  @Output() logout = new EventEmitter<void>();
  @Output() openNotifications = new EventEmitter<void>();
  @Output() showSurveyDialog = new EventEmitter<void>();

  get activeSurveyIndicator() {
    return this.numberOfActiveSurveys;
  }

  sidebarItems = [
    {
      icon: 'sidebar-home.svg',
      name: 'home',
      route: 'stories',
      showOnMobile: true,
    },
    {
      icon: 'sidebar-people.svg',
      name: 'people',
      route: 'people',
      showOnMobile: true,
    },
    {
      icon: 'sidebar-shouts.svg',
      name: 'shoutbox',
      route: 'shoutbox',
      showOnMobile: true,
    },
    {
      icon: 'sidebar-surveys.svg',
      name: 'surveys',
      clickFn: () => this.showSurveyDialog.emit(),
      indicatorValue: 'survey',
    },
    {
      icon: 'sidebar-notifications.svg',
      name: 'notifications',
      // clickFn: () => this.openNotifications.emit(),
      route: 'notifications',
      indicatorValue: 'notification',
    },
    {
      icon: 'sidebar-add-story.svg',
      name: 'addStory',
      route: 'story/new',
    },
  ];

  logoutClicked() {
    this.logout.emit();
  }
}

<div class="container">
  <div #backdrop class="backdrop">
    <div class="highlights" [innerHTML]="highlightedText"></div>
  </div>
  <span class="p-float-label {{ textAreaKey }}">
    <textarea
      [(ngModel)]="textValue"
      (ngModelChange)="valueUpdated($event)"
      (scroll)="handleScroll()"
      #textarea
      pInputTextarea
      [mention]="mentionItems"
      [mentionConfig]="{
        labelKey: 'label',
        disableSearch: true,
        dropUp: showMentionsAbove
      }"
      [mentionListTemplate]="mentionListTemplate"
      (opened)="mentionSearchChanged('')"
      (searchTerm)="mentionSearchChanged($event)"
      (itemSelected)="mentionItemSelected($event)"
    ></textarea>
    <label class="text-area-label">{{ label }}</label>
  </span>
</div>

<ng-container
  *ngIf="{ loadMentionOptions: loadMentionOptions$ | async } as data"
></ng-container>

<ng-template #mentionListTemplate let-item="item">
  <div
    class="select-mention-user"
    [ngClass]="{ 'user-inactive': item.inactive }"
  >
    <span
      >{{ item.name }}
      <ng-container *ngIf="item.inactive">(Inactive)</ng-container></span
    >
  </div>
</ng-template>

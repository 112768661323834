import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Router } from '@angular/router';
import { AuthService } from 'libs/services/auth';
import { MessageService } from 'primeng/api';
import { of } from 'rxjs';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';
import * as authActions from './auth.actions';

@Injectable()
export class AuthEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.login),
      switchMap(({ loginData }) =>
        this.service.login(loginData).pipe(
          map((response) => {
            const token = response.token;
            localStorage.setItem('token', token);
            return authActions.loginSuccess();
          }),
          catchError((err) => of(authActions.loginError()))
        )
      )
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.loginSuccess),
        switchMap(() =>
          this.service.getLoggedUserData().pipe(
            map((user) => {
              this.messageService.clear();
              localStorage.setItem('currentUser', JSON.stringify(user));
              this.router.navigate(['./..']).then(() => {
                window.location.reload();
              });
              return authActions.loginSetCurrentUser({ user });
            }),
            catchError((err) => of(authActions.loginError()))
          )
        )
      ),
    { dispatch: false }
  );

  getLoggedUsersData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.getLoggedUsersData),
      switchMap(() =>
        this.service.getLoggedUserData().pipe(
          map((user) => {
            localStorage.setItem('currentUser', JSON.stringify(user));
            return authActions.loginSetCurrentUser({ user });
          }),
          catchError((err) => of(authActions.logout()).pipe(delay(1500)))
        )
      )
    )
  );

  loginError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.loginError),
        tap(() => {
          this.messageService.add({
            severity: 'error',
            summary: 'Login failed',
            detail: 'Invalid credentials',
          });
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.logout),
        tap(() => {
          localStorage.clear();
          this.router.navigate(['login']).then(() => {
            window.location.reload();
          });
        })
      ),
    { dispatch: false }
  );

  requestActivationCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.requestActivationCode),
      switchMap(({ email }) =>
        this.service.requestActivationCode(email).pipe(
          map((response) => {
            return authActions.requestActivationCodeSuccess();
          }),
          catchError((err) => of(authActions.requestActivationCodeError()))
        )
      )
    )
  );

  requestActivationCodeError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.requestActivationCodeError),
        tap(() => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error occured on generate',
          });
        })
      ),
    { dispatch: false }
  );

  setPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.setPassword),
      switchMap(({ data }) =>
        this.service.setPassword(data).pipe(
          map(() => {
            const loginData = {
              email: data.email,
              password: data.password,
            };

            return authActions.setPasswordSuccess({ loginData });
          }),
          catchError((err) => of(authActions.setPasswordError()))
        )
      )
    )
  );

  setPasswordSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.setPasswordSuccess),
      map(({ loginData }) => authActions.login({ loginData }))
    )
  );

  setPasswordError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.setPasswordError),
        tap(() => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error occured while setting password',
          });
        })
      ),
    { dispatch: false }
  );

  updateCurrentUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.updateCurrentUser),
        tap((action) => {
          localStorage.setItem('currentUser', JSON.stringify(action.user));
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private service: AuthService,
    private router: Router,
    private messageService: MessageService
  ) {}
}

import { createAction, props } from '@ngrx/store';
import { Survey } from 'libs/types/survey';

export const loadSurvey = createAction(
  '[Survey] Load Survey',
  props<{ id: string }>()
);

export const loadSurveySuccess = createAction(
  '[Survey] Load Survey Success',
  props<{ survey: Survey }>()
);

export const loadSurveyError = createAction(
  '[Survey] Load Survey Error',
  props<{ error: any }>()
);

export const loadActiveSurveys = createAction('[Survey] Load Active Survey');

export const loadActiveSurveysSuccess = createAction(
  '[Survey] Load Active Survey Success',
  props<{ surveys: Survey[] }>()
);

export const loadActiveSurveysError = createAction(
  '[Survey] Load Active Survey Error',
  props<{ error: any }>()
);

export const loadActiveSurveysCount = createAction(
  '[Survey] Load Active Survey Count'
);

export const loadActiveSurveysCountSuccess = createAction(
  '[Survey] Load Active Survey Count Success',
  props<{ count: number }>()
);

export const loadActiveSurveysCountError = createAction(
  '[Survey] Load Active Survey Count Error',
  props<{ error: any }>()
);

export const toggleSurveyDialog = createAction(
  '[Survey] Toggle Survey Dialog',
  props<{ visible: boolean }>()
);

export const clearCurrentSurvey = createAction('[Survey] Clear Current Survey');

export const surveyAnsweredSuccess = createAction(
  '[Survey] Survey Answered Success'
);

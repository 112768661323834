import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthState } from 'libs/states/auth';
import * as authActions from 'libs/states/auth/+state/auth.actions';
import * as authSelectors from 'libs/states/auth/+state/auth.selectors';
import * as notificationsActions from 'libs/states/notifications/+state/notifications.actions';
import { NotificationsState } from 'libs/states/notifications/+state/notifications.reducer';
import * as notificationsSelectors from 'libs/states/notifications/+state/notifications.selectors';
import * as surveyActions from 'libs/states/survey/+state/survey.actions';
import { SurveyState } from 'libs/states/survey/+state/survey.reducer';
import * as surveySelectors from 'libs/states/survey/+state/survey.selectors';
import { UiState } from 'libs/states/ui-state/+state/ui.reducer';
import * as uiSelectors from 'libs/states/ui-state/+state/ui.selectors';
import { filter, interval, tap } from 'rxjs';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {
  searchText = '';
  isAuth = false;

  isAuth$ = this.authState.select(authSelectors.getIsAuth).pipe(
    tap((isAuth) => {
      this.isAuth = isAuth;

      if (isAuth) {
        this.notificationState.dispatch(
          notificationsActions.loadUnreadNotificationsCount()
        );
        this.surveyState.dispatch(surveyActions.loadActiveSurveysCount());
      }
    })
  );

  notificationsLoaded$ = this.notificationState.select(
    notificationsSelectors.getNotificationsLoaded
  );

  notifications$ = this.notificationState.select(
    notificationsSelectors.getNotifications
  );

  notificationsVisible$ = this.notificationState.select(
    notificationsSelectors.getNotificationsVisible
  );

  userProfileImage$ = this.authState.select(
    authSelectors.getCurrentUserProfileImage
  );

  checkForUpdates$ = interval(60 * 1000).pipe(
    filter(() => this.isAuth),
    tap(() => {
      this.notificationState.dispatch(
        notificationsActions.loadUnreadNotificationsCount()
      );

      this.surveyState.dispatch(surveyActions.loadActiveSurveysCount());
    })
  );

  unreadNotificationsCount$ = this.notificationState.select(
    notificationsSelectors.getUnreadNotificationsCount
  );

  surveyDialogVisible$ = this.surveyState.select(
    surveySelectors.getSurveyDialogVisible
  );

  surveys$ = this.surveyState.select(surveySelectors.getSurveys);

  surveysLoaded$ = this.surveyState.select(surveySelectors.getSurveysLoaded);

  numberOfActiveSurveys$ = this.surveyState.select(
    surveySelectors.getNumberOfActiveSurveys
  );

  surveySidebarActive$ = this.surveyState.select(
    surveySelectors.getSurveySidebarActive
  );

  isMobile$ = this.uiState.select(uiSelectors.getIsMobile);

  constructor(
    private authState: Store<AuthState>,
    private notificationState: Store<NotificationsState>,
    private uiState: Store<UiState>,
    private surveyState: Store<SurveyState>,
    private router: Router
  ) {}

  logout() {
    this.authState.dispatch(authActions.logout());
  }

  toggleNotificationsDialog(visible: boolean) {
    this.notificationState.dispatch(
      notificationsActions.toggleNotificationsVisible({ visible })
    );
  }

  loadAllNotifications() {
    this.notificationState.dispatch(
      notificationsActions.loadAllNotifications()
    );
  }

  loadSurveys() {
    this.surveyState.dispatch(surveyActions.loadActiveSurveys());
  }

  toggleSurveyDialog(visible: boolean) {
    this.surveyState.dispatch(surveyActions.toggleSurveyDialog({ visible }));
  }

  surveySelected(surveyId: string) {
    this.toggleSurveyDialog(false);

    this.router.navigate(['survey/', surveyId]);
  }
}

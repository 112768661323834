import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prepareMentionText',
  pure: true,
})
export class PrepareMentionTextPipe implements PipeTransform {
  transform(value: string): any {
    let newValue = value;

    const regex = /\[@[a-zA-Zšđčćž]+:\d+\]/g;

    const matches = newValue.matchAll(regex);

    if (matches) {
      for (const match of matches) {
        const mention = match[0] as string;

        const usernameEnd = mention.indexOf(':');

        const mentionUsername = mention.slice(1, usernameEnd);

        const userIdEnd = mention.indexOf(']');

        const userId = mention.slice(usernameEnd + 1, userIdEnd);

        const mentionedUserHtml = `<mark class="clickable user-id=${userId}">${mentionUsername}</mark>`;

        newValue = newValue.replaceAll(match[0], mentionedUserHtml);
      }
    }

    return newValue;
  }
}

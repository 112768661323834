import { createAction, props } from '@ngrx/store';
import { Notification } from 'libs/types/notifications/notification.type';

export const loadAllNotifications = createAction(
  '[Notifications] Load All Notificaions'
);

export const loadAllNotificationsSuccess = createAction(
  '[Notifications] Load All Notificaions Success',
  props<{ notifications: Notification[] }>()
);

export const loadAllNotificationsError = createAction(
  '[Notifications] Load All Notificaions Error',
  props<{ error: any }>()
);

export const toggleNotificationsVisible = createAction(
  '[Notifications] Toggle Notifications Visible',
  props<{ visible: boolean }>()
);

export const loadUnreadNotificationsCount = createAction(
  '[Notifications] Load Unread Notificaions Count'
);

export const loadUnreadNotificationsCountSuccess = createAction(
  '[Notifications] Load Unread Notificaions Count Success',
  props<{ count: number }>()
);

export const loadUnreadNotificationsCountError = createAction(
  '[Notifications] Load Unread Notificaions Count Error',
  props<{ error: any }>()
);

import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthVisibleForm } from 'libs/enums/auth';
import { AuthState } from 'libs/states/auth';
import * as authActions from 'libs/states/auth/+state/auth.actions';
import * as authSelectors from 'libs/states/auth/+state/auth.selectors';
import { LoginData, SetPasswordData } from 'libs/types/auth';

@Component({
  selector: 'app-auth-container',
  templateUrl: './auth-container.component.html',
  styleUrls: ['./auth-container.component.scss'],
})
export class AuthContainerComponent {
  visibleFormEnum = AuthVisibleForm;

  authStep$ = this.authState.select(authSelectors.getAuthStep);

  activeAccountStep$ = this.authState.select(
    authSelectors.getActivateAccountStep
  );

  forgotPasswordStep$ = this.authState.select(
    authSelectors.getForgotPasswordStep
  );

  constructor(private authState: Store<AuthState>) {}

  login(loginData: LoginData) {
    this.authState.dispatch(authActions.login({ loginData }));
  }

  updateActivateAccountStep(step: number) {
    this.authState.dispatch(authActions.updateActivateAccountStep({ step }));
  }

  resetPassword(email: string) {
    this.authState.dispatch(authActions.requestActivationCode({ email }));
  }

  navigateToForm(step: number) {
    this.authState.dispatch(authActions.updateAuthStep({ step }));
  }

  setPassword(data: SetPasswordData) {
    this.authState.dispatch(authActions.setPassword({ data }));
  }

  updateForgotPasswordStep(step: number) {
    this.authState.dispatch(authActions.updateForgotPasswordStep({ step }));
  }
}

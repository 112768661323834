import { ISurvey } from './interfaces/survey.interface';
import { SurveyQuestion } from './survey-question.type';

export class Survey {
  id: string;
  title: string;
  introText: string;
  answerTime: number;
  endsAt: Date;
  questions: SurveyQuestion[];

  constructor(data: ISurvey, questions?: SurveyQuestion[]) {
    this.id = data.id;
    this.title = data.attributes.title;
    this.introText = data.attributes.intro_text;
    this.answerTime = data.attributes.answer_time;
    this.endsAt = data.attributes.ends_at;

    if (questions) this.questions = questions;
  }
}

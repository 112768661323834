import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'libs/shared/shared.module';
import { AuthStateModule } from 'libs/states/auth';
import { NotificationsStateModule } from 'libs/states/notifications/notifications-state.module';
import { SurveyStateModule } from 'libs/states/survey/survey-state.module';
import { UiStateModule } from 'libs/states/ui-state/ui-state.module';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { NotificationsDialogComponent } from './components/notifications-dialog/notifications-dialog.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ActiveSurveysDialogComponent } from './components/survey-dialog/active-surveys-dialog.component';
import { LayoutComponent } from './containers/layout/layout.component';
import { NotificationIconPipe } from './pipes/notification-icon.pipe';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: LayoutComponent,
    // children: [
    //   { path: '', redirectTo: 'home', pathMatch: 'full' },
    //   {
    //     path: 'home',
    //     loadChildren: () =>
    //       import('libs/stories/stories.module').then((m) => m.StoriesModule),
    //   },
    // ],
  },
];

@NgModule({
  declarations: [
    LayoutComponent,
    SidebarComponent,
    NotificationsDialogComponent,
    NotificationIconPipe,
    ActiveSurveysDialogComponent,
  ],
  imports: [
    CommonModule,
    InputTextModule,
    FormsModule,
    AuthStateModule,
    UiStateModule,
    NotificationsStateModule,
    DialogModule,
    ScrollPanelModule,
    ProgressSpinnerModule,
    SurveyStateModule,
    TranslateModule,
    SharedModule,
    RouterModule.forChild(routes),
  ],
  exports: [LayoutComponent],
})
export class LayoutModule {}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  NotificationsState,
  notificationsFeatureKey,
} from './notifications.reducer';

export const selectNotificationsState =
  createFeatureSelector<NotificationsState>(notificationsFeatureKey);

export const getNotificationsLoaded = createSelector(
  selectNotificationsState,
  (state) => state.notificationsLoaded
);

export const getNotifications = createSelector(
  selectNotificationsState,
  (state) => state.notifications
);

export const getNotificationsVisible = createSelector(
  selectNotificationsState,
  (state) => state.visible
);

export const getUnreadNotificationsCount = createSelector(
  selectNotificationsState,
  (state) => state.unreadNotificationsCount
);

import { Action, createReducer, on } from '@ngrx/store';
import { Notification } from 'libs/types/notifications/notification.type';
import * as actions from './notifications.actions';

export const notificationsFeatureKey = 'notifications';

export interface NotificationsState {
  notificationsLoaded: boolean;
  notifications: Notification[];
  visible: boolean;
  unreadNotificationsCount: number;
}

export const initialState: NotificationsState = {
  notificationsLoaded: false,
  notifications: [],
  visible: false,
  unreadNotificationsCount: 0,
};

export const notificaionsReducer = createReducer(
  initialState,
  on(actions.loadAllNotifications, (state) => {
    return {
      ...state,
      notifications: [],
      notificationsLoaded: false,
    };
  }),
  on(actions.loadAllNotificationsSuccess, (state, action) => {
    return {
      ...state,
      notifications: action.notifications,
      notificationsLoaded: true,
    };
  }),
  on(actions.toggleNotificationsVisible, (state, action) => {
    return {
      ...state,
      visible: action.visible,
      notifications: [],
      notificationsLoaded: action.visible ? state.notificationsLoaded : false,
    };
  }),
  on(actions.loadUnreadNotificationsCountSuccess, (state, action) => {
    return {
      ...state,
      unreadNotificationsCount: action.count ?? 0,
    };
  })
);

export function reducer(state: NotificationsState, action: Action) {
  return reducer(state, action);
}
